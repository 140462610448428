import { __decorate } from "tslib";
import { LitElement, html } from "lit";
import { customElement } from "lit/decorators.js";
import { style as sharedStyle } from "@mypinpad/design-system/dist/wc/branding-config-style-css";
import { Provider } from "@mypinpad/js-lib/mixins/mixins";
import { UrlController } from "../controllers/url-controller";
import "../components/top-nav/top-nav";
import "../components/footer/footer";
import { style } from "./not-found-style-css";
import { AuthController } from "../controllers/auth-controller";
let NotFound = class NotFound extends Provider(LitElement) {
    constructor() {
        super();
        this.urlController = new UrlController(this);
        this.authController = new AuthController(this);
        this.provideInstance("url-controller", this.urlController);
    }
    static get styles() {
        return [sharedStyle, style];
    }
    connectedCallback() {
        super.connectedCallback();
        if (this.urlController.getPathname() !== "/not-found")
            window.location.replace("/not-found");
    }
    render() {
        if (this.authController.loggedIn)
            return html `
        <pap-top-nav
          class="mb-auto"
          accountName=${this.authController.account.name}
          accountUsername=${this.authController.account.username}
          @logout-click=${() => this.authController.logout()}
        ></pap-top-nav>

        <div class="d-flex mx-auto flex-column bg-white p-5 shadow-sm">
          <div class="d-flex align-items-center">
            <svg
              width="32"
              height="33"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clip-path="url(#a)">
                <path
                  d="M4 .955a4 4 0 0 0-4 4v24a4 4 0 0 0 4 4h24a4 4 0 0 0 4-4v-24a4 4 0 0 0-4-4H4Zm12 8c1.07 0 1.908.924 1.8 1.99l-.7 7.014a1.104 1.104 0 0 1-2.2 0l-.7-7.014a1.81 1.81 0 0 1 1.8-1.99Zm.004 12a2 2 0 1 1 0 4 2 2 0 0 1 0-4Z"
                  fill="#F5821E"
                />
              </g>
              <defs>
                <clipPath id="a">
                  <path
                    fill="#fff"
                    transform="translate(0 .955)"
                    d="M0 0h32v32H0z"
                  />
                </clipPath>
              </defs>
            </svg>
            <h1 class="m-0 ms-2">404</h1>
          </div>
          <h5 class="mt-2 sub-title">
            The page you’re looking for can’t be found!
          </h5>
          <p class="text">
            Please return to our <a href="../overview/">homepage</a> or
            <a href="https://support.mypinpad.com/">contact us</a> if the
            problem persists
          </p>
        </div>

        <pap-footer class="container p-0 mt-auto d-flex"></pap-footer>
      `;
        else
            return html ``;
    }
};
NotFound = __decorate([
    customElement("pap-not-found")
], NotFound);
export { NotFound };
